<template>
  <v-app id="inspire">
    <v-app-bar app>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <img height="50" class="ml-15 mr-5" src="../assets/img/logo.png" alt="" />
      <v-toolbar-title class="text-h5"
        >长春天新合成材料有限公司</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-btn color="primary" to="/" class="mx-8">
        首页
      </v-btn>
      <v-toolbar-title>订购电话：18943128669</v-toolbar-title>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-row>
        <v-col cols="3">
          <v-card class="mx-auto" max-width="400">
            <v-list>
              <v-list-item-group
                v-model="model"
                active-class="border"
                color="indigo"
              >
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  @click="(url = `${item.to}`), getNumPages()"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="9"
          ><div style="width:100%">
            <pdf
              ref="pdf"
              v-for="i in numPages"
              :key="i"
              :src="url"
              :page="i"
            ></pdf></div
        ></v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      url: "pdf/1.pdf",
      numPages: null, // pdf 总页数
      items: [
        {
          text: "地下水环境自行检测方案(2021)",
          to: "pdf/1.pdf",
        },
        {
          text: "地下水检测报告(2021)",
          to: "pdf/2.pdf",
        },
        {
          text: "土壤环境自行检测方案(2021)",
          to: "pdf/3.pdf",
        },
        {
          text: "土壤检测报告(2021)",
          to: "pdf/4.pdf",
        },
        {
          text: "土壤污染防治责任书(2021)",
          to: "pdf/5.pdf",
        },
        {
          text: "地下水检测报告(2022)",
          to: "pdf/6.pdf",
        },
        {
          text: "土壤和地下水污染隐患排查报告(2022)",
          to: "pdf/7.pdf",
        },
        {
          text: "土壤污染隐患排查台账(2022)",
          to: "pdf/8.pdf",
        },
        {
          text: "土壤检测报告(2022)",
          to: "pdf/9.pdf",
        },
      ],
      model: 0,
    };
  },
  mounted() {
    this.getNumPages();
  },
  methods: {
    //计算pdf页码总数
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf 加载失败", err);
        });
    },
  },
};
</script>
