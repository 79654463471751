<template>
  <v-app id="inspire">
    <v-app-bar app collapse-on-scroll>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <img height="50" class="ml-15 mr-5" src="../assets/img/logo.png" alt="" />
      <v-toolbar-title class="text-h5"
        >长春天新合成材料有限公司</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-btn color="primary" to="/gongshi" class="mx-8">
        环保公示
      </v-btn>
      <v-toolbar-title>订购电话：18943128669</v-toolbar-title>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-card tile flat class="d-flex justify-center">
        <v-card tile flat max-width="1366" min-width="1066">
          <!-- 标题 -->
          <v-card
            height="100"
            tile
            flat
            class="d-flex align-center justify-center"
          >
            <h1 class="text-h3">长春天新合成材料有限公司</h1>
          </v-card>
          <!-- 轮播图 -->
          <v-container fluid>
            <v-row>
              <v-carousel
                cycle
                height="500"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item>
                  <v-img
                    max-height="100%"
                    max-width="100%"
                    src="../assets/img/1.jpg"
                  ></v-img>
                </v-carousel-item>
                <v-carousel-item>
                  <v-img
                    max-height="100%"
                    max-width="100%"
                    src="../assets/img/2.jpg"
                  ></v-img>
                </v-carousel-item>
                <v-carousel-item>
                  <v-img
                    max-height="100%"
                    max-width="100%"
                    src="../assets/img/3.jpg"
                  ></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-row>
          </v-container>
          <!-- 公司简介 -->
          <v-container>
            <v-row class="d-flex align-center">
              <v-col cols="5">
                <v-sheet class="d-flex align-center">
                  <div>
                    <h1 class="text-h3">ENTERPRISE</h1>
                    <h1 class="text-h3">INTRODUCTION</h1>
                    <h2 class="text-h5">公司简介</h2>
                  </div>
                </v-sheet>
              </v-col>
              <v-col>
                <v-sheet>
                  <p class="text-h7">
                    长春天新合成材料有限公司（以下简称“长春天新”）的前身是
                    一家成立于 1956 年的军工企业，注册资本 1500
                    万元，是吉林省高新
                    企业，科技小巨人企业。长春天新作为一家大型的化工品生产和科研
                    机构，拥有专业的研发团队和完善的生产、管理、销售和售后服务体
                    系。尤其是作为公司投入最大的研发团队，通过多年来的不断发展和
                    完善，完备了研发体系和流程，具有专业权威的检测手段和先进的科
                    学检测仪器。<br />
                    产品涵盖隧道掘进用化学品、特种润滑油脂、金属加工工艺介质
                    及特种金属表面处理。长春天新研发从成分研究入手，在产品定型、
                    品质提升和大规模批量生产方面获得了丰富的宝贵经验。尤其在隧道
                    用土壤改性泡沫剂、盾尾密封油脂、主轴密封油脂 HBW
                    和盾构专用极 压锂基脂 EP2
                    方面，天新公司取得了重大的技术突破。产品全部技术
                    参数达到国际先进水平，部分关键技术参数国际领先。
                  </p>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
          <!-- 视差 -->
          <v-parallax height="300" src="../assets/img/4.jpg"></v-parallax>
          <!-- 产品简介 -->
          <v-card
            height="80"
            tile
            flat
            class="d-flex align-end justify-center mt-5"
          >
            <h1 class="text-h4">主营产品介绍</h1>
          </v-card>
          <v-container fluid class="pa-0">
            <!-- <v-sheet height="20" color="while">土壤改性泡沫剂</v-sheet> -->
            <v-card flat tile height="400">
              <v-card-title>土壤改性泡沫剂</v-card-title>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-sheet tile>
                    <v-carousel
                      hide-delimiters
                      show-arrows-on-hover
                      height="335"
                    >
                      <v-carousel-item
                        src="../assets/img/6.jpg"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                      <v-carousel-item
                        src="../assets/img/7.jpg"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                  </v-sheet>
                </v-col>
                <v-col cols="7">
                  <v-expansion-panels flat tile v-model="panel[0]">
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >TX-F110/F240/F300/F430系列土壤改性润滑泡沫剂</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        TX-F110/F240/F300/F430系列土壤改性润滑泡沫剂是适用于盾构隧道掘进施工的专用土壤改性泡沫剂产品。本品适用于各种土壤条件，能使砂类土得到良好的流动性和止水性。并具有一定的支撑作用;特别对粘性土发挥良好的防结饼和润滑作用，使开挖土塑性流动，并提高其止水性。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >应用概述</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        在盾构隧道施工过程中，由于土壤的摩擦力大、透水性高、遗士的流动性差，不能很好地传递压力，保持开挖面稳定难度很大，这就限制了土压平衡盾构的适应范围。为解决此问题,必须采取土体改良措施,以保证渣士的流动性和减少渣士的透水性，使开挖面保持稳定。添加本品能改变土壤渗透系数、扩大土压平衡盾构机适用土体及粒径的范围，并且加注泡沫剂还可减少刀盘与土体的摩擦，降低扭矩，减少壳体与刀盘上粘土的粘着力,有利于排土机出土,所需的驱动功率便可减少。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >产品优点</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        <ul>
                          <li>
                            1.润滑:减少盾构机机械的磨损，降低切削渣土的内摩擦力。可以有效减少刀盘、螺旋输送机的磨损，
                          </li>
                          <li>
                            2.土壤改性:调整土仓内土体塑性流动性，解决土仓内“泥饼"、“堵塞”等问题，提升掘进效率。
                          </li>
                          <li>
                            3.安全:降低渣士的透水性，有效防止超进中喷涌的发生。
                          </li>
                          <li>
                            4.环保:产品无毒无腐蚀，气泡到地面后随压力变化两个小时自行消失，可以生物降解。
                          </li>
                          <li>
                            5.灵活:结合我司生产的专用黏土分散剂或渣土聚合剂，可以进一步灵活满足各种地质条件的需要。
                          </li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          <v-container fluid class="pa-0">
            <!-- <v-sheet height="20" color="while">土壤改性泡沫剂</v-sheet> -->
            <v-card flat tile height="370">
              <v-card-title>盾尾密封油脂</v-card-title>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-sheet tile>
                    <v-carousel
                      show-arrows-on-hover
                      hide-delimiters
                      height="304"
                    >
                      <v-carousel-item
                        src="../assets/img/8.jpg"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                      <v-carousel-item
                        src="../assets/img/9.jpg"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                  </v-sheet>
                </v-col>
                <v-col cols="7">
                  <v-expansion-panels flat tile v-model="panel[1]">
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >产品说明</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        X-TG101/TG201盾尾密封油脂是适用于盾构隧道掘进施工的专用盾尾密封油脂产品。盾尾密封油脂用于盾构掘进机钢丝刷型盾尾，可以兼用于泵送或者手涂方式起到密封地下水(淡水或海水)、泥浆和膨润土泥浆的作用，确保盾构机的水密性。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >应用概述</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        在盾构掘进过程中，通过注脂系统之间注入油脂,借助油脂的挤压力填充密封隧道衬砌与钢丝刷之间的运动间隙，增强钢丝刷的密封性能，从而使盾尾形成一-层牢固的密封层，
                        防止泥水和土砂渗入。盾尾油脂可以有效保护盾尾，隔绝泥浆，保证盾构的顺利推进，同时对盾尾钢结构有防锈、防腐蚀和减少磨损的作用。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >产品优点</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        水压密封性:隔绝泥水和泥浆的渗入，特别适用于高水压等恶劣环境，保障盾构顺利掘进。
                        泵送性:保证良好的水压密封性的同时，盾尾密封油脂具有很好的流动性，降低机泵注入油脂的难度的同时，易于充填各种可能滲水的缝隙，并且降低所需驱动功率。
                        润滑性:降低盾尾与管片外壁之间的摩擦力，加快盾构的推进和保护盾尾装置。
                        环保性:对环境无伤害，可降解。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          <v-container fluid class="pa-0">
            <!-- <v-sheet height="20" color="while">土壤改性泡沫剂</v-sheet> -->
            <v-card flat tile height="400">
              <v-card-title>主轴密封油脂HBW</v-card-title>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-sheet tile>
                    <v-carousel
                      show-arrows-on-hover
                      hide-delimiters
                      height="335"
                    >
                      <v-carousel-item
                        src="../assets/img/10.jpg"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                  </v-sheet>
                </v-col>
                <v-col cols="7">
                  <v-expansion-panels flat tile v-model="panel[2]">
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >产品说明</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        TX-HBW100是-一种盾构机主轴专用润滑脂。天新公司针对中铁装备盾构机设备作业特点。克服业内同类产品不足的前提下,为客户提供粘附性更好、泵送性更佳、加强止水密封性和橡胶适应性的优秀专用主轴润滑脂。并且满足环境友好可降解的要求。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >应用概述</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        在盾构设备中，刀盘主轴承的密封系统由三道加强型的丁晴橡胶唇形密封组成，三道密封属口两两之间采用不间断的油脂填充起到密封空款和润滑的作用。盾构机刀盘般是在多水。多泥、多盼尘、高压高负荷的工作环境下运行的。主轴密封油脂密封效果的好坏，在盾构机运行过程中非常重要，否则一旦发生泥水穿过润滑脂，达到主轴承问隙内，引发主轴承或齿轮损坏，将会对盾构机带来不可估量的损失。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >产品优点</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        耐水止水性优异，加强的附著力，高密度和粘附性，确保螺旋邮件的优良密封性能。优秀的预送性和润滑性。耐海水沖失性能业内领先(小于3%)极佳的橡胶适应性，从橡胶密封材质入手，针对接触面、支撑面和唇口起到保护和润滑的作用。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          <v-container fluid class="pa-0">
            <!-- <v-sheet height="20" color="while">土壤改性泡沫剂</v-sheet> -->
            <v-card flat tile height="520">
              <v-card-title>盾构机专用抗磨液压油</v-card-title>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-sheet tile>
                    <v-carousel
                      show-arrows-on-hover
                      hide-delimiters
                      height="455"
                    >
                      <v-carousel-item
                        src="../assets/img/12.jpg"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                  </v-sheet>
                </v-col>
                <v-col cols="7">
                  <v-expansion-panels flat tile v-model="panel[3]">
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >产品说明</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        盾构掘进机作为目前世界地下施工掘进工程的主要设备，在使用中要求高洁净度盾构专用抗磨液压油，该油品由于对颗粒污染物(洁净度)的要求十分苛刻。此外，由于盾构掘进机属于重型施工设备，其负荷大，设备工作压力高，因此设备的制造精度也要求十分严格，特别是其液压系统的高压工作，对液压油提出了更加苛刻的要求。克瑞格品牌TXL系列盾构机专用抗磨液压油，根据盾构设备机械的特殊要求，针对性提升油品关键指标，全面满足设备的严苛要求。
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >产品优点</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        <h4>优异的洁净度</h4>
                        NAS 8级以下，充分满足盾构机专用设备的精密度要求。
                        <h4>行业领先的粘温性能</h4>
                        由于液压油处于高压下工作,防止渗漏是液压系统正常工作的基本要求，液压油工作中温度在不停地变化，油品不能因为温度的改变而产“生粘度的较大变化，而导致液压油的渗漏。克瑞格品牌TXL系列盾构机专用抗磨液压油针对性调整粘温特性，以确保系统的密封性和适应性。
                        <h4>极强的适应性</h4>

                        克瑞格品牌TXL系列盾构机专用抗磨液压油具有良好的抗乳化性能和防锈性能，以确保油品被水分污染时不产生乳化和引起设备生锈。
                        <h4>加强润滑和极压性</h4>

                        由于液压泵需要产生高压力，从而产生巨大推力，因此部件制造十分精密，部件间的摩擦力较大,产生的温度也较高，同时针对液压泵的自润滑结构。克瑞格品牌TXL系列盾构机专用抗磨液压油针对上述具体要求，在指标和产品体系上做了专门的研发，产品具有行业领先的润滑性(抗磨性能)。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          <v-container fluid class="pa-0">
            <!-- <v-sheet height="20" color="while">土壤改性泡沫剂</v-sheet> -->
            <v-card flat tile height="550">
              <v-card-title>盾构机专用复合极压锂基润滑酯</v-card-title>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-sheet tile>
                    <v-carousel
                      show-arrows-on-hover
                      hide-delimiters
                      height="480"
                    >
                      <v-carousel-item
                        src="../assets/img/11.jpg"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                  </v-sheet>
                </v-col>
                <v-col cols="7">
                  <v-expansion-panels flat tile v-model="panel[4]">
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >产品说明</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        长久以来，盾构机使用的润滑脂以进口通用性产品为主,因其工艺并不是专为盾构机设备需求而设计，并未从机械结构要求出发完全满足盾构设备的运转条件。特别是在抗乳化性，剪切安定性和极压性上功能不足。克瑞格TX-EP210盾构机专用复合极压锂基润滑脂在这些关键功能上,进-步提高抗水冲刷性、流变性和黏附性，填补了行业空白。
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="teal lighten-3"
                        class="text-h6"
                        >产品优点</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="grey lighten-3">
                        <h4>优异的密封性能</h4>

                        极压锂基润滑脂主要应用于盾构机主轴承润滑密封的5道唇形密封中的2个密封仓内，主要作用是润滑主轴及防止泥浆、灰尘或污染物通过密封件进入主轴承润滑系统，克瑞格TX-EP210良好的黏附性是优异的密封性能的保障。
                        <h4>极佳的泵送性</h4>

                        在盾构机主轴承润滑过程中，先用气动泵将润滑脂输送到多点泵，再通过多点泵上的每个柱塞泵,将润滑脂输送到相应的供油点。唇形密封构成的2个腔室中都注入了润滑脂。克瑞格TX-EP210提供了高VI值，满足了在较宽的温度范围内拥有非常好的泵送性。
                        <h4>加强的极压抗磨性</h4>

                        在四球机和梯姆肯试验机的严苛检测条件下,我们为克瑞格TX-EP210加强了极压润滑性的设计，满足地质突变或者脱困时主轴承承载的巨大负荷要求。
                        <h4>领先的抗水性能</h4>

                        克瑞格TX-EP210在黏附性、抗乳化性和剪切安定性上依照装备研究院的指导,完善了工艺设计。使得盾构机在恶劣的工作环境中依然具备良好的抵抗地下水(淡水和海水)、泥水浆和膨润土泥浆
                        的能力。
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-card>
      </v-card>
    </v-main>
    <v-footer class="justify-center">
      <v-sheet class="text-center">
        <h5>长春天新合成材料有限公司</h5>
        <a href="https://beian.miit.gov.cn"
          ><h5>网站备案/许可证号：吉ICP备2021003534号-1</h5></a
        >

        <h5>
          <a href="https://pan.baidu.com/s/19pSO0bG80Z8AshzD1pb1fw"
            >环保公示信息 (提取码：1234)</a
          >
        </h5>
      </v-sheet>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    panel: [0, 0, 0, 0, 0],
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
  }),
};
</script>
